<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="优惠券名称:">
                    <el-input placeholder="请输入优惠券名称" v-model="queryParams.name" clearable></el-input>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="上线" :value="2"></el-option>
                        <el-option label="下线" :value="1"></el-option>
                        <el-option label="已下架" :value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </el-form-item>
            </el-form>
            <el-form :inline="true" class="demo-form-inline">
                <el-button type="primary" @click="add()">新增</el-button>
            </el-form>
            <!-- 表格内容 -->
            <el-table v-loading="loading" :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable" style="margin-top:20px">
                <el-table-column prop="id" label="id" width="50" align="center"></el-table-column>
                <el-table-column prop="resource_id" label="类型id" align="center"></el-table-column>
                <el-table-column prop="name" label="优惠券名称" width="200" align="center"></el-table-column>
                <el-table-column prop="price" label="优惠劵价格" width="90" align="center"></el-table-column>
                <el-table-column prop="ctime" label="优惠券开始时间" width="160" align="center"></el-table-column>
                <el-table-column prop="etime" label="优惠券结束时间" width="160" align="center"></el-table-column>
                <el-table-column prop="full_price" label="满足金额" align="center"></el-table-column>
                <el-table-column prop="stock" label="库存" width="100" align="center"></el-table-column>
                <el-table-column prop="is_receive" label="是否可领取" width="120" align="center">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.is_receive === 2">可领取</el-tag>
                        <el-tag type="info" v-if="scope.row.is_receive === 1">不可领取</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="resource" label="优惠券类型" align="center" width="120">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.resource === 0">全场</el-tag>
                        <el-tag type="primary" v-if="scope.row.resource === 1">品牌</el-tag>
                        <el-tag type="primary" v-if="scope.row.resource === 3">专场</el-tag>
                        <el-tag type="primary" v-if="scope.row.resource === 4">套装购</el-tag>
                        <el-tag type="primary" v-if="scope.row.resource === 5">拼团</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="ty" label="折扣类型" width="100" align="center">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.ty === 1">直减</el-tag>
                        <el-tag type="primary" v-if="scope.row.ty === 2">折扣</el-tag>
                    </template>
                </el-table-column>

                <el-table-column label="状态" prop="state" align="center">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 3">已下架</el-tag>
                    </template>
                </el-table-column>

                <el-table-column label="操作" align="center" width="170" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            上线</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
            <!-- 新增 编辑 -->
            <el-dialog :title="title" :visible.sync="addVisible" width="600px" v-if="addVisible" class="dialog_box">
                <el-form ref="form" :model="form" label-width="120px" :rules="rules" label-position="left">
                    <el-form-item label="优惠劵类型" prop="resource">
                        <el-radio-group v-model="form.resource">
                            <el-radio :label="1">品牌</el-radio>
                            <el-radio :label="0">全场</el-radio>
                            <el-radio :label="3">专场</el-radio>
                            <!-- <el-radio :label="4">套装购</el-radio>
                            <el-radio :label="5">拼团</el-radio> -->
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="活动id " prop="resource_id">
                        <el-input v-model="form.resource_id" placeholder="请输入活动id" :disabled="disabled"></el-input>
                    </el-form-item>
                    <el-form-item label="优惠劵名称" prop="name">
                        <el-input v-model="form.name" placeholder="请输入优惠名称"></el-input>
                    </el-form-item>
                    <el-form-item label="优惠劵库存" prop="stock">
                        <el-input v-model="form.stock" placeholder="请输入优惠劵库存"></el-input>
                    </el-form-item>
                    <el-form-item label="优惠劵价格 " prop="price">
                        <el-input v-model="form.price" placeholder="请输入优惠劵价格"></el-input>
                    </el-form-item>
                    <el-form-item label="满足金额" prop="full_price">
                        <el-input v-model="form.full_price" placeholder="请输入金额"></el-input>
                    </el-form-item>
                    <el-form-item v-if="form.resource===0" label="限制不可用商品" prop="ids">
                        <el-input v-model="form.ids" placeholder="请输入商品id用，分割"></el-input>
                    </el-form-item>
                    <el-form-item label="限制领取数量" prop="limit">
                        <el-input v-model="form.limit" placeholder="请输入限制领取数量"></el-input>
                    </el-form-item>
                    <el-form-item label="起止时间" prop="time">
                        <el-date-picker ref="drawDate" :picker-options="pickerOptions" v-model="form.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="是否可领取" prop="is_receive">
                        <el-radio-group v-model="form.is_receive">
                            <el-radio :label="2">是</el-radio>
                            <el-radio :label="1">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="折扣类型" prop="ty">
                        <el-radio-group v-model="form.ty">
                            <el-radio :label="2">折扣</el-radio>
                            <el-radio :label="1">直减</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="状态" prop="state">
                        <el-radio-group v-model="form.state">
                            <el-radio :label="2">上线</el-radio>
                            <el-radio :label="1">下线</el-radio>
                            <el-radio :label="3">已下架</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveData">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 上线、下线提示框 -->
            <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
                <div class="del-dialog-cnt">{{ confirmContent }}</div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="confirmVisible = false">取 消</el-button>
                    <el-button type="primary" @click="stateData">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
export default {
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            disabled: false,
            menuList: ['营销', '优惠券管理'],
            title: '加载中',
            addVisible: false,
            form: {
                state: 1,
            },
            rules: {
                name: [{
                    required: true,
                    message: '优惠券名称不能为空',
                    trigger: 'blur'
                }],
                resource_id: [{
                    required: true,
                    message: '活动id不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^[0-9]*$',
                    message: "只能输入数字"
                }],
                stock: [{
                    required: true,
                    message: '优惠券库存不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^[0-9]*$',
                    message: "只能输入数字"
                }],
                price: [{
                    required: true,
                    message: '优惠券价格不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^(([1-9]{1}\\d*)|([0]{1}))(\\.(\\d){0,2})?$',
                    message: "只能输入整数或小数点后两位数字"
                }],
                full_price: [{
                    required: true,
                    message: '满足金额不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^(([1-9]{1}\\d*)|([0]{1}))(\\.(\\d){0,2})?$',
                    message: "只能输入整数或小数点后两位数字"
                }],
                time: [{
                    required: true,
                    message: '起止时间不能为空',
                    trigger: 'blur'
                }],
                limit: [{
                    required: true,
                    message: '限制领取数量不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^[0-9]*$',
                    message: "只能输入数字"
                }],
                is_receive: [{
                    required: true,
                    message: '是否可领取不能为空',
                    trigger: 'blur'
                }],
                ty: [{
                    required: true,
                    message: '折扣类型不能为空',
                    trigger: 'blur'
                }],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],
                resource: [{
                    required: true,
                    message: '优惠券类型不能为空',
                    trigger: 'blur'
                }],
                ids: [{
                    required: false,
                    message: '优惠券id不能为空',
                    trigger: 'blur',
                },
                {
                    pattern: '^(\\d+,?)+$',
                    message: "请输入数字id,逗号分割"
                }]
            },
            pageInfo: {
                list: [],
            },

            queryParams: {
                ctime: '',
                etime: '',
                page: 1,
                pagesize: 10,
                platform: 'admin'
            },

            confirmContent: '',
            confirmVisible: false,
            states: [
                {
                    state: 7,
                    value: "全部",
                    type: "primary"
                }, {
                    state: 1,
                    value: "待付款"
                },
                {
                    state: 2,
                    value: "已完成并签收",
                },
                {
                    state: 3,
                    value: "代发货"
                },
                {
                    state: 4,
                    value: "删除"
                },
                {
                    state: 5,
                    value: "用户取消"
                },
                {
                    state: 6,
                    value: "系统自动取消"
                },

            ],
            pickerOptions: {
                disabledDate(time) {
                    // return time.getTime() > Date.now();
                },
            },
        };
    },
    created() {
        this.loadData()
    },
    methods: {
        // 图片上传 
        handleAvatarSuccess(res, file) {
            if (res.code == 1) {
                this.$set(this.form, 'image', res.data.uri);
            }
        },
        beforeAvatarUpload(file) {
            const _this = this
            return deal.imageFormat(file, _this)
        },
        //新增编辑
        saveData() {
            if (this.$refs.form) {
                console.log(33333, this.form)
                this.$refs.form.validate(valid => {
                    if (valid) {
                        var url = this.mode == 'add' ? '/coupon/add' : '/coupon/edit';
                        this.form.ctime = deal.timestamp(this.form.time[0]);
                        this.form.etime = deal.timestamp(this.form.time[1]);
                        //处理时间
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                                this.imageUrl = '';
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$refs.form.clearValidate();
                        this.$message.error('请补全信息');

                        return false;
                    }
                });
            };
        },
        //新增
        add() {
            this.mode = 'add';
            this.title = '新增优惠券';
            this.disabled = false
            this.form = {
                state: 2,
                getProductState: 1,
                ty: 2,
                resource: 1,
                is_receive: 2
            };
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        //编辑
        edit(index, row) {
            this.mode = 'edit';
            this.disabled = true
            this.title = '编辑优惠券';
            this.current = row.id;
            const item = this.pageInfo.list[index];
            let time = [item.ctime, item.etime]
            console.log(2222, time)
            //处理时间
            this.form = Object.assign({}, item);
            this.$set(this.form, 'time', time);
            this.form.resource_id = this.form.resource_id + ''
            this.form.stock = this.form.stock + ''
            this.form.price = this.form.price + ''
            this.form.full_price = this.form.full_price + ''
            this.form.limit = this.form.limit + ''
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        // 状态
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.form.ctime = deal.timestamp(this.form.ctime);
            this.form.etime = deal.timestamp(this.form.etime);
            this.confirmVisible = true;
        },
        getProductState(row) {
            let stateTip = '';
            let state = row.state;
            let is_complete = row.is_complete;
            let refund_state = row.refund_state ? row.refund_state : '';
            switch (+state) {
                case 1:
                    stateTip = '待审核'; break;
                case 2:
                    stateTip = '审核通过'; break;
                case 3:
                    stateTip = '已拒绝'; break;
                case 4:
                    stateTip = '删除'; break;
                    break;
            }
            switch (+refund_state) {
                case 1:
                    stateTip = '等待买家退货'; break;
                case 2:
                    stateTip = '买家已发货'; break;
                    break;
                case 3:
                    stateTip = '仓库确认收货'; break;
                    break;
            }
            switch (+is_complete) {
                case 2:
                    stateTip = '已完成';
                    break;
            }
            return stateTip;
        },
        loadData() {
            this.loading = true;
            //优惠券列表
            request.get('/coupon/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                    this.pageInfo.list.map(function (val) {
                        val.ctime = deal.timestamp(val.ctime, 'unix');
                        val.etime = deal.timestamp(val.etime, 'unix');
                        return val;
                        // if (val.goods[0]) {
                        //     val.goods = val.goods[0].list;
                        // }
                        // return val;
                    });
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val
            this.loadData()
        },
        refreshPageNum(val) {
            this.queryParams.page = val
            this.loadData()
        },
        // 确定下线
        stateData() {
            request.post('/coupon/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        }
    }
};
</script>

<style  scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}
/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}
</style>